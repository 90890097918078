const $ = window.$;

// tesmonial slider
$(document).ready(function(){
	$('.testmonial-slider').not('.slick-initialized').slick({
		dots: true,
		infinite: true,
		lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1
	});
});

// images slider
$(document).ready(function(){

	$('.img-slider').not('.slick-initialized').slick({

		// dots: true,
		infinite: true,
		speed: 800,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 6,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{

				breakpoint: 320,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}

			}
		]
	});

});