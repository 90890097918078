import {Helmet} from "react-helmet";

const websiteUrl = `https://teachersdais.com/`
const websiteImage = `https://td-website-assets.s3.us-east-2.amazonaws.com/teachersdais-social.png`

const meta = [    
    {name: `card`, content: `summary`},
    {name: `site`, content: websiteUrl},
    {name: `image`, content: websiteImage},
    {name: `twitter:card`, content: `summary`},
    {name: `twitter:site`, content: websiteUrl},
    {name: `twitter:image`, content: websiteImage},
    {name: `og:card`, content: `summary`},
    {name: `og:site`, content: websiteUrl},
    {name: `og:image`, content: websiteImage},
]

const metaHome = [ 
    {name: `title`, content: `Your One Stop Online teaching app | Online teaching software | TeachersDais`},
    {name: `description`, content: `Online Tutoring is now easier with TeachersDais online teaching software ✓Fully customizable website for your business ✓Create courses ✓Schedule your classes in any timezone ✓On-demand video support ✓Take attendance ✓Collect fees in any currency ✓Live classes ✓Conduct tests & quizzes.`},
    {name: `twitter:title`, content: `Your One Stop Online teaching app | Online teaching software | TeachersDais`},
    {name: `description`, content: `Online Tutoring is now easier with TeachersDais online teaching software ✓Fully customizable website for your business ✓Create courses ✓Schedule your classes in any timezone ✓On-demand video support ✓Take attendance ✓Collect fees in any currency ✓Live classes ✓Conduct tests & quizzes.`},
    {name: `og:title`, content: `Your One Stop Online teaching app | Online teaching software | TeachersDais`},
    {name: `description`, content: `Online Tutoring is now easier with TeachersDais online teaching software ✓Fully customizable website for your business ✓Create courses ✓Schedule your classes in any timezone ✓On-demand video support ✓Take attendance ✓Collect fees in any currency ✓Live classes ✓Conduct tests & quizzes.`},
]

const metaAboutUs = [ 
    {name: `title`, content: `About us`},
    {name: `description`, content: `TeachersDais is an educational platform providing a ready-to-use online teaching application and website for your business helping you build your own brand globally.`},
    {name: `twitter:title`, content: `About us`},
    {name: `description`, content: `TeachersDais is an educational platform providing a ready-to-use online teaching application and website for your business helping you build your own brand globally.`},
    {name: `og:title`, content: `About us`},
    {name: `description`, content: `TeachersDais is an educational platform providing a ready-to-use online teaching application and website for your business helping you build your own brand globally.`},
]

const metaVision = [ 
    {name: `title`, content: `Our Vision`},
    {name: `description`, content: `TeachersDais is the ultimate solution for teachers, academies and institutions looking for a single hub that allows them to take their businesses online and reach students the world over.`},
    {name: `twitter:title`, content: `Our Vision`},
    {name: `description`, content: `TeachersDais is the ultimate solution for teachers, academies and institutions looking for a single hub that allows them to take their businesses online and reach students the world over.`},
    {name: `og:title`, content: `Our Vision`},
    {name: `description`, content: `TeachersDais is the ultimate solution for teachers, academies and institutions looking for a single hub that allows them to take their businesses online and reach students the world over.`},
]

const metaFaq = [ 
    {name: `title`, content: `TeachersDais Faq | Everything you need to learn about our platform and online teaching`},
    {name: `description`, content: `Get answers for all your questions with our faq's that will guide you through your teaching journey.`},
    {name: `twitter:title`, content: `TeachersDais Faq | Everything you need to learn about our platform and online teaching`},
    {name: `description`, content: `Get answers for all your questions with our faq's that will guide you through your teaching journey.`},
    {name: `og:title`, content: `TeachersDais Faq | Everything you need to learn about our platform and online teaching`},
    {name: `description`, content: `Get answers for all your questions with our faq's that will guide you through your teaching journey.`},
]

const metaContactUs = [ 
    {name: `title`, content: `TeachersDais Contact Us | We'd love to hear from you`},
    {name: `description`, content: `Have questions about our products, features, trails or pricing? Need a demo? Our teams will help you.`},
    {name: `twitter:title`, content: `TeachersDais Contact Us | We'd love to hear from you`},
    {name: `description`, content: `Have questions about our products, features, trails or pricing? Need a demo? Our teams will help you.`},
    {name: `og:title`, content: `TeachersDais Contact Us | We'd love to hear from you`},
    {name: `description`, content: `Have questions about our products, features, trails or pricing? Need a demo? Our teams will help you.`},
]


export const SEOHomePage = () =>{      
    return(
        
     <Helmet 
        htmlAttributes={{ lang: "en" }}
        meta={meta.concat(metaHome)}
      />
     )
}

export const SEOAboutUs = () =>{      
    return(
        
     <Helmet 
        htmlAttributes={{ lang: "en" }}
        meta={meta.concat(metaAboutUs)}
      />
     )
}

export const SEOOurVision = () =>{      
    return(
        
     <Helmet 
        htmlAttributes={{ lang: "en" }}
        meta={meta.concat(metaVision)}
      />
     )
}

export const SEOFaq = () =>{      
    return(
        
     <Helmet 
        htmlAttributes={{ lang: "en" }}
        meta={meta.concat(metaFaq)}
      />
     )
}

export const SEOContactUs = () =>{      
    return(
        
     <Helmet 
        htmlAttributes={{ lang: "en" }}
        meta={meta.concat(metaContactUs)}
      />
     )
}