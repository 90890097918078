export function contactUsSave(data) {    
    return {
        type:  'CONTACT_US_SUBMIT_DATA',
        data,
    };
}
export function contactUsSaveSuccess(data) {
    return {
        type:  'CONTACT_US_SUBMIT_DATA_SUCCESS',
        data,
    };
}
export function contactUsSaveError(error) {
    return {
        type:  'CONTACT_US_SUBMIT_DATA_ERROR',
        error,
    };
}
export function contactUsClearDate() {
    return {
        type:  'CONTACT_US_CLEAR_DATA',        
    };
}