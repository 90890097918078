import React from 'react';

export default function SubContent(){
    return (	
        <section className="content-wrapper Subs-sec">
    		<div className="container">
    			<div className="heading-wrapper heading-desc">
    				<h2>Lorem ipsum dolor sit amet, consectetur</h2>
    				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
    			</div>
    		</div>
    	</section>   
    );
}