import React from 'react';
import contactUs from '../../../assets/images/contactUs.png'

export default function ContactUsBanner(){
    return (	        
    <section className="about-baner top-baner">
        <div className="container">
            <div className="row">    				
                <div className="col-lg-6 col-sm-7 align-self-center">
                    <div className="heading heading-desc">
                        <h2>We'd love to hear from you</h2>	
                        <p>Have questions about our products, features, trails or pricing? Need a demo? Our teams will help you.</p>					
                    </div>
                </div>
                <div className="col-lg-6 col-sm-5">
                    <div className="inner-banner-img">
                        <img src={contactUs} />
                    </div>
                </div>
            </div>    			
        </div>
    </section>     
    );
}