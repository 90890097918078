import React from 'react';
import Slider from "react-slick";
import teachfromAnywhere from './banner/01.png'
import platformAllYours from './banner/02.png'
import scalableWayToTeach from './banner/03.png'
import knowledgeToIncome from './banner/04.png'
import whyWait from './banner/05.png'
import {captureGA, captureGAEvent} from "../../../utils/common"
import { Link } from "react-router-dom";

export default function HomeBanner(){  

    const settings_home_banner = {
        dots: true,
		infinite: true,
		speed: 800,
		autoplay: false,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					// dots: true
				}
			},
			{
			breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
			breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
					}
			}
		]
    };

    return (	
    <>      
	<section className="slider-baner">
		<div className="container-width">
			<Slider {...settings_home_banner} className="baner-slider">	    		
				<div className="slick-slider">
					<div className="baner-slider-contnet">
						<div className="sliderbaner-title">
							<div className="center-width">
								<div className="heading heading-desc">
									<h2>Launch your online academy in a few clicks</h2>
									<ul className="slide-list">
										<li>Flexible, global teaching platform</li>
										<li>Turn your skills and knowledge into income</li>										
									</ul>																										
								</div>
								<div className="baner-btn">									
									<Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Carousel", "Home Page")} className="btans free-trial"><b>Schedule A FREE Demo</b></Link>									
								</div>
							</div>
						</div>
						<div className="slider-lady-img">
							<img src={teachfromAnywhere} className="slider-top-space"/>
						</div>
					</div>
					</div>
				<div className="slick-slider">
					<div className="baner-slider-contnet">
						<div className="sliderbaner-title">
							<div className="center-width">
								<div className="heading heading-desc">
									<h2>A platform that’s all yours</h2>
									<ul className="slide-list">
										<li>Get a fully customizable, personal website</li>
										<li>Keep everything you need in one dashboard</li>
										<li>Connect easily with students via chat and video</li>
									</ul>									
								</div>	
							</div>
						</div>
						<div className="slider-lady-img">
							<img src={platformAllYours}/>
						</div>
					</div>
					</div>
				<div className="slick-slider">
					<div className="baner-slider-contnet">
						<div className="sliderbaner-title">
							<div className="center-width">
								<div className="heading heading-desc">
									<h2>A scalable way to teach</h2>
									<ul className="slide-list">
										<li>Host live, scheduled video classes</li>
										<li>Connect with students via inbuilt chat</li>
										<li>Create and share syllabi with students</li>
									</ul>										
								</div>	
							</div>
						</div>
						<div className="slider-lady-img">
							<img src={scalableWayToTeach}/>
						</div>
					</div>
					</div>
				<div className="slick-slider">
					<div className="baner-slider-contnet">
						<div className="sliderbaner-title">
							<div className="center-width">
								<div className="heading heading-desc">
									<h2>Turn your knowledge into income</h2>
									<ul className="slide-list">
										<li>Collect fees in any currency</li>
										<li>Connect Google Pay, Stripe and Paypal</li>
										<li>Manage checkout and coupons easily</li>
									</ul>										
								</div>	
							</div>
						</div>
						<div className="slider-lady-img">
							<img src={knowledgeToIncome}/>
						</div>
					</div>
				</div>
				<div className="slick-slider">
					<div className="baner-slider-contnet">
						<div className="sliderbaner-title">
							<div className="center-width">
								<div className="heading heading-desc">
									<h2>Why wait?</h2>
									<ul className="slide-list">
										<li>Ready to change lives?</li>
										<li>Schedule a demo with one of our team to see what TeachersDais can do for your business.</li>										
									</ul>								
								</div>
								<div className="baner-btn">
									<Link to="/contactus" onClick={() => captureGAEvent("Contact Us", "Carousel", "Home Page")} className="btans get-started">Contact Us</Link>
									<Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Carousel", "Home Page")} className="btans free-trial"><b>Schedule A FREE Demo</b></Link>
								</div>	
							</div>
						</div>
						<div className="slider-lady-img">
							<img className="image-80 slider-top-space" src={whyWait}/>
						</div>
					</div>
				</div>				
			</Slider>
		</div>
	</section>        
    </>           
    );  
}