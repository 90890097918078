import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { contactUsReducer } from  '../containers/ContactUs/reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    contactUsReducer
  });
 
  export default createRootReducer;
