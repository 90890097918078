import { connect } from 'react-redux';
import ContactUsEnquiries from '../../components/ContactUs';
import { contactUsSave, contactUsClearDate } from '../ContactUs/actions';

const mapStateToProps = (state) => ({
    contactUsSaveSuccess: state.contactUsReducer.contactUsSaveSuccess,
    contactUsSaveError: state.contactUsReducer.contactUsSaveError,
})

export function mapDispatchToProps(dispatch) {
  return {    
    contactUsSave: (data) => dispatch(contactUsSave(data)), 
    contactUsClearDate: () => dispatch(contactUsClearDate()),    
    dispatch,
  };
}

const contactUs = connect(mapStateToProps,mapDispatchToProps)(ContactUsEnquiries);

export default contactUs;