import React from 'react';
import ContactUsBanner from './ContactUsBanner';
import ContactUsEnquiries from './ContactUsEnquiries';
import ContactUsLocations from './ContactUsLocations';
import HomeContentTwo from '../Home/HomeContentTwo';
import {SEOContactUs} from "../../utils/SEO"

class ContactUs extends React.Component {
    constructor(props) {
      super(props);
    }    
    componentDidMount() {
      window.scrollTo(0, 0);
      this.props.contactUsClearDate();
    }
    render() {      
      return (			
        <>
        <SEOContactUs/>
        <ContactUsBanner />
        <section className="contact-sec">
    		<div className="container">
    			<div className="row">
                    <ContactUsLocations/>
                    <ContactUsEnquiries {...this.props} />                    
                </div>
            </div>
        </section>
        <HomeContentTwo />                           
        </>              
      );
    }
}

export default ContactUs;