import React from 'react';

export default function HomeVideo(){
    return (       	
        <section>
            <video className="vedio-sec" controls controlsList="nodownload">
                <source src="https://td-website-assets.s3.us-east-2.amazonaws.com/TEACHERSDAIS.mp4" type="video/mp4"/>
            </video>          
        </section>         
    );
}