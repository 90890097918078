import React from 'react';

export default function AboutJourney(){
    return (			
        <section className="content-wrapper journey-bg">
    		<div className="container">
    			<div className="heading-wrapper oversion-content">
    				<h2>Our story</h2>
    				<p>COVID-19 has brought disruption to every market, and education has been no exception. Every student, teacher and parent has had to adjust to a new way of learning.</p>
    				<p>But the global pandemic has also exposed the inequalities inherent in the education system. At TeachersDais, we envision a future where people from all backgrounds and in all locations can learn what they love from the best teachers in the world.</p>
					<p>TeachersDais is our solution to a world without classrooms. We believe a virtual learning environment opens the door for students who might not traditionally have access to educational establishments.</p>
				</div>
    		</div>
		</section>  
    );
}