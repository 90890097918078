import React from 'react';
import VisionBanner from './VisionBanner';
import VisionContent from './VisionContent';
import HomeContentTwo from '../Home/HomeContentTwo';
import {SEOOurVision} from "../../utils/SEO"

class Vision extends React.Component {
    constructor(props) {
      super(props);
    }    
    render() {
      return (			
        <>
          <SEOOurVision/>
          <VisionBanner />
          <VisionContent />  
          <HomeContentTwo />                           
        </>              
      );
    }
}

export default Vision;