import React from 'react';
import logo from '../../assets/images/logo.PNG'
import '../../assets/js/main.js'
import ScrollButton from '../../ScrollButton '
import { Link } from "react-router-dom";
import {captureGA, captureGAEvent} from "../../utils/common"

export default function Footer(){
    return (
        <>
        <footer className="foter-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-12">
                        <div className="logo-img">
                            <a href="#">
                                <img src={logo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                        <div className="foter-all-menu">
                            <div className="foter-menu">
                                <ul className="nav-menu">
                                <li className="nav-item">                                    
                                    <Link to="/" onClick={() => captureGA("/home")} className="nav-link">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/aboutus" onClick={() => captureGA("/aboutus")} className="nav-link">About Us</Link> 
                                </li>                                
                                </ul>
                            </div>
                            <div className="foter-menu">
                                <ul className="nav-menu">
                                    {/*<li className="nav-item">
                                    <a className="nav-link" href="Subscription.html">Subscription</a>
                                    </li>*/}
                                    <li className="nav-item">
                                        <Link to="/vision" onClick={() => captureGA("/vision")} className="nav-link">Our Vision</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/faq" onClick={() => captureGA("/faq")} className="nav-link">FAQs</Link>  
                                    </li>                                    
                                </ul>
                            </div>
                            <div className="foter-menu">
                                <ul className="nav-menu">
                                    <li className="nav-item">
                                        <Link to="/contactus" onClick={() => captureGA("/contactus")} className="nav-link">Contact Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/marketplace" onClick={() => captureGA("/marketplace")} className="nav-link">Marketplace</Link>
                                    </li>
                                    {/*<li className="nav-item">
                                        <a className="nav-link" href="#">Log In</a>
                                    </li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-12">
                        <div className="header-icon">
                            <div className="icon-title-content"> Connect with us</div>
                            <ul className="icons foter-icon">
                                <li><a href="https://www.facebook.com/TeachersDais-114217917834435" className="icons-link" onClick={() => captureGAEvent("Social Media", "Facebook", "Footer")}><i className="fa fa-facebook facebook-2x" aria-hidden="true"></i></a></li>
                                <li><a href="https://twitter.com/teachersdais" className="icons-link" onClick={() => captureGAEvent("Social Media", "Twitter", "Footer")}><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/teachersdais/" className="icons-link" onClick={() => captureGAEvent("Social Media", "LinkedIn", "Footer")}><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12">
                        <div className="email-content">
                            <div className="foter-content">
                                <span className="foter-icons"><i className="fa fa-phone" aria-hidden="true"></i></span>
                                <span className="icon-title"><a href="tel:+12243997101" onClick={() => captureGAEvent("Phone Number", "Phone", "Footer")}>(+1) 224 399 7101</a></span>
                            </div>
                            <div className="foter-content">
                                <span className="foter-icons"><i className="fa fa-envelope-o" aria-hidden="true"></i></span>
                                <span className="icon-title"><a href="mailto:admin@teachersdais.com" onClick={() => captureGAEvent("Email Address", "Email", "Footer")}>admin@teachersdais.com</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    	</footer>
    	<footer className="foter-botam">
    		<div className="container">
    			<div className="row">
    				<div className="col-lg-8">
    					<div className="copy-right">
    						<p>© TeachersDais 2021. All Rights Reserved</p>
    					</div>
    				</div>
    				<div className="col-lg-4">
                        <ScrollButton />
    				</div>
    			</div>
    		</div>
    	</footer>            
        </>
    );
}