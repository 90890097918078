import React from 'react';
import AboutBanner from './AboutBanner';
import AboutJourney from './AboutJourney';
import AboutCounting from './AboutCounting';
import HomeContentTwo from '../Home/HomeContentTwo';
import {SEOAboutUs} from "../../utils/SEO"

class About extends React.Component {
    constructor(props) {
      super(props);
    }    
    render() {
      return (			
        <>
          <SEOAboutUs/>
          <AboutBanner />          
          <AboutJourney />   
          <HomeContentTwo />                           
        </>
              
      );
    }
}

export default About;