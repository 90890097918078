import React from 'react';
import classroom from '../../../assets/images/home/classroom.PNG'
import classroomArrow from '../../../assets/images/icon-img.PNG'

export default function HomeContentOne(){ 
  return (			       
    <section className="content-wrapper">
      <div className="heading-wrapper heading-desc">
        <h2 className="world-heading" style={{textAlign: "center"}}>Make the world your classroom</h2>
      </div>
      <div className="row border-botam">
        <div className="col-lg-4">
          <div className="make-img">
            <img src={classroom}/>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="make-content">
            <div className="heading-title">
              <span className="icons"><img src={classroomArrow}/></span>
              <span className="heading-text">Ed-Tech start-up</span>
              <p>If you’ve got knowledge to share with the world, TeachersDais has the platform to help you do it. At TeachersDais, we believe knowledge should not be constrained by geography. It’s our mission to help spread the knowledge of individual teachers, coaching centers and institutions across the world.</p>
            </div>
            <div className="heading-title">
              <span className="icons"><img src={classroomArrow}/></span>
              <span className="heading-text">Better reach</span>
              <p>Our unique and customizable platform will help you build your online presence as a teacher, plan and run your courses, manage your cohorts, and reach the students who need your expertise.</p>
            </div>
            <div className="heading-title">              
              <p>You’ve got the knowledge. We’ve got the platform. Together, we can teach the world.</p>
            </div>           
          </div>
        </div>
      </div>
    </section>     
  );    
}