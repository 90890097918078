import React from 'react';
import HomeBanner from './HomeBanner';
import HomeContentOne from './HomeContentOne';
import HomeDashboards from './HomeDashboards';
import HomeVideo from './HomeVideo';
import HomeWhyTD from './HomeWhyTD';
import HomeSubscribe from './HomeSubscribe';
import HomeContentTwo from './HomeContentTwo';
import {SEOHomePage} from "../../utils/SEO"

class Home extends React.Component {
    constructor(props) {
      super(props);
    }    
    render() {
      return (			
        <>
          <SEOHomePage/>         
          <HomeBanner />
          <HomeContentOne />
          <HomeDashboards />
          <HomeVideo />
          <HomeWhyTD />
          <HomeSubscribe />
          <HomeContentTwo />            
        </>
              
      );
    }
}

export default Home;