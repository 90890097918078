import React from 'react';
import faq from '../../../assets/images/faq.png'

export default function FaqBanner(){
    return (	        
	<section className="about-baner top-baner">
		<div className="container">
			<div className="row">    				
				<div className="col-lg-6 col-sm-7 align-self-center">
					<div className="heading heading-desc">
						<h2>We're here to help</h2>						
					</div>
				</div>
				<div className="col-lg-6 col-sm-5">
					<div className="inner-banner-img">
						<img src={faq} />
					</div>
				</div>
			</div>    			
		</div>
	</section>    
    );
}