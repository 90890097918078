import React from 'react';

export default function SubTier(){
    return (	
        <section className="content-wrapper Subscription-sec">
    		<div className="container">
    			<div className="heading-wrapper heading-desc">
    				<h2>Lorem ipsum dolor sit amet, consectetur</h2>
    				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
    			</div>
    			<div className="row">
    				<div className="col-lg-3 col-sm-3 col-6">
    					<div className="Subscription-box">
    						<div className="Subscription-content">
    							<h3>Free</h3>
    							<p>Lorem ipsum dolor sit amet, consectetur od tempor incididunt ut labore et dolore magna aliqua.</p>	
    						</div>
    						<div className="Subscription-btn stat">
    							<a href="#">Stat Free</a>
    						</div>
    					</div>
    				</div>
    				<div className="col-lg-3 col-sm-3 col-6">
    					<div className="Subscription-box">
    						<div className="Subscription-content">
    							<h3>Application</h3>
    							<p>Lorem ipsum dolor sit amet, consectetur od tempor incididunt ut labore et dolore magna aliqua.</p>	
    						</div>
    						<div className="Subscription-btn contact">
    							<a href="#">Contact Us</a>
    						</div>
    					</div>
    				</div>
    				<div className="col-lg-3 col-sm-3 col-6">
    					<div className="Subscription-box">
    						<div className="Subscription-content">
    							<h3>Platform</h3>
    							<p>Lorem ipsum dolor sit amet, consectetur od tempor incididunt ut labore et dolore magna aliqua.</p>
    						</div>
    						<div className="Subscription-btn contact">
    							<a href="#">Contact Us</a>
    						</div>
    					</div>
    				</div>
    				<div className="col-lg-3 col-sm-3 col-6">
    					<div className="Subscription-box">
    						<div className="Subscription-content">
    							<h3>Unlimited</h3>
    							<p>Lorem ipsum dolor sit amet, consectetur od tempor incididunt ut labore et dolore magna aliqua.</p>	
    						</div>
    						<div className="Subscription-btn contact">
    							<a href="#">Contact Us</a>
    						</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</section>     
    );
}