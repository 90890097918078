import React from 'react';

export default function VisionContent(){
    return (	
        <section className="content-wrapper">
    		<div className="container">
    			<div className="heading-wrapper heading-desc oversion-content">
					<h2>Our vision</h2>
    				<p>Our vision is a world where everyone, regardless of where they’ve come from or where they live, can learn valuable skills and build a career they love. Our teaching technology connects students and teachers from all over the world, inspiring and enabling people of all backgrounds to change their lives for the better.</p>
    				<p>We see a world where education is increasingly accessible and individualized. A world where technology enables rather than encumbers. A world where students and teachers can connect, learn, and grow.</p>
    			</div>
    		</div>
    	</section>   
    );
}