import React from 'react';

export default function SubBanner(){
    return (	
        <section className="Subscription-baner top-baner">
    		<div className="container">
    			<div className="row">
    				<div className="col-lg-6 col-sm-6">
		     				<div className="heading heading-desc small-title">
		     					<h2>Lorem ipsum <br/> consectetur</h2>
		     				</div>
    				</div>
    				<div className="col-lg-6 col-sm-6">
    				</div>
    			</div>
    		</div>
    	</section>   
    );
}