import React from 'react';
import FaqBanner from './FaqBanner';
import FaqSection from './FaqSection';
import HomeContentTwo from '../Home/HomeContentTwo';
import {SEOFaq} from "../../utils/SEO"

class Faq extends React.Component {
    constructor(props) {
      super(props);
    }    
    render() {
      return (			
        <>
          <SEOFaq/>
          <FaqBanner />
          <FaqSection />          
          <HomeContentTwo />                           
        </>
              
      );
    }
}

export default Faq;