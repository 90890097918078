import React from 'react';
import logo from '../../assets/images/logo.PNG'
import { Link } from "react-router-dom";
import {captureGA, captureGAEvent} from "../../utils/common"

export default function Header(){  

    const mobileMenu = () => {        
        var x = document.getElementById("mobileclick");
        if (x.style.display === "block") {
          x.style.display = "none";          
        } else {
          x.style.display = "block";           
        }
    }

    const closeMobileMenu = () => {        
        var x = document.getElementById("mobileclick");        
        x.style.display = "none";           
    }

    return (
        <>
            <section className="header main_header">
                <header className="top-header" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-sm-3 content-middle">
                            </div>
                            <div className="col-lg-7 col-sm-9">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-4">
                                        {/*<div className="header-btn">
                                            <a href="#" className="sign-login-btn font-bold-600">Sign Up</a>
                                            <a href="#" className="sign-login-btn font-bold-600">Log in</a>
                                        </div>*/}
                                    </div>
                                    <div className="col-lg-5 col-sm-5">
                                        <div className="header-title heading-desc">
                                            <h1 className="font-bold-600">Connect with us</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="header-icon">
                                            <ul className="icons">
                                                <a href="https://www.facebook.com/TeachersDais-114217917834435" className="icons-link" onClick={() => captureGAEvent("Social Media", "Facebook", "Header")}><li><i className="fa fa-facebook" aria-hidden="true"></i></li></a>
                                                <a href="https://twitter.com/teachersdais" className="icons-link" onClick={() => captureGAEvent("Social Media", "Twitter", "Header")}><li><i className="fa fa-twitter" aria-hidden="true"></i></li></a>
                                                <a href="https://www.linkedin.com/company/teachersdais/" className="icons-link" onClick={() => captureGAEvent("Social Media", "LinkedIn", "Header")}><li><i className="fa fa-linkedin" aria-hidden="true"></i></li></a>
                                            </ul>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <header className="hader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-2 logo">
                                <div className="logo-img">
                                    <a href="#">
                                        <img src={logo}/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-9 col-sm-10 content-middle">
                                <div className="header-menu font-bold-600">
                                    <ul className="nav-menu">
                                        <li className="nav-item">                                        
                                            <Link to="/" onClick={() => captureGA("/home")} className="nav-link">Home</Link>   
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/aboutus" onClick={() => captureGA("/aboutus")} className="nav-link">About Us</Link>                                        
                                        </li>
                                        <li className="nav-item">                                        
                                            <Link to="/vision" onClick={() => captureGA("/vision")} className="nav-link">Our Vision</Link>
                                        </li>
                                        {/*<li className="nav-item">
                                            <Link to="/subscription" className="nav-link">Subscription</Link> 
                                        </li>*/}
                                        <li className="nav-item">                                       
                                            <Link to="/faq" onClick={() => captureGA("/faq")} className="nav-link">FAQs</Link>  
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketplace" onClick={() => captureGA("/marketplace")} className="nav-link">Marketplace</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/contactus" onClick={() => captureGA("/contactus")} className="nav-link">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
            <section className="header mobile">
                {/*<header className="top-header" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-4 contact_top">
                                        <div className="header-title heading-desc">
                                            <h1>Connect with us</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 top_icon">
                                        <div className="header-icon">
                                            <ul className="icons">
                                                <a href="#" className="icons-link"><li><i className="fa fa-facebook" aria-hidden="true"></i></li></a>
                                                <a href="#" className="icons-link"><li><i className="fa fa-instagram" aria-hidden="true"></i></li></a>
                                                <a href="#" className="icons-link"><li><i className="fa fa-youtube-play" aria-hidden="true"></i></li></a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>                                 
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="header-btn">
                                    <a href="#" className="sign-login-btn">Sign Up</a>
                                    <a href="#" className="sign-login-btn">Log in</a>
                                </div>
                            </div>                                    
                        </div>
                    </div>
                </header>*/}
                <header className="hader">
                    <div className="container">
                        <div className="row">
                            <div className="col-7 col-sm-3">
                                <div className="logo-img">
                                    <a href="#">
                                        <img src={logo}/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-5 col-sm-9 humber-icon">
                                <a href={void(0)} className="icon mob-nav" onClick={() => mobileMenu()}>
                                    <i className="fa fa-bars"></i>
                                </a>
                            </div>
                            <div className="col-lg-8 col-sm-9">
                                <div className="header-menu" id="mobileclick" style={{display:"none"}}>
                                    <ul className="nav-menu" className="menu nav-menu" aria-expanded="false">
                                        <li className="nav-item">
                                            <Link to="/" onClick={() => {closeMobileMenu() ; captureGA("/home")} } className="nav-link">Home</Link> 
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/aboutus" onClick={() => {closeMobileMenu() ; captureGA("/aboutus")} } className="nav-link">About Us</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/vision" onClick={() => {closeMobileMenu() ; captureGA("/vision")}} className="nav-link">Our Vision</Link>
                                        </li>
                                        {/*<li className="nav-item">
                                            <Link to="/subscription" onClick={() => closeMobileMenu()} className="nav-link">Subscription</Link>
                                        </li>*/}
                                        <li className="nav-item">
                                            <Link to="/faq" onClick={() => {closeMobileMenu(); captureGA("/faq")}} className="nav-link">FAQs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketplace" onClick={() => {closeMobileMenu(); captureGA("/marketplace")}} className="nav-link">Marketplace</Link>
                                        </li>                                        
                                        <li className="nav-item">
                                            <Link to="/contactus" onClick={() => {closeMobileMenu(); captureGA("/contactus")}} className="nav-link">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        </>
    );
}
