import React from 'react';
import aboutUs from '../../../assets/images/aboutUs.png'

export default function AboutBanner(){
    return (	
        <>        
		<section className="about-baner top-baner">
    		<div className="container">
    			<div className="row">    				
					<div className="col-lg-6 col-sm-7 align-self-center">
						<div className="heading heading-desc">
							<h2>Go digital to go global</h2>
							<p>We’re an educational platform connecting teachers and students the world over</p>
						</div>
					</div>
					<div className="col-lg-6 col-sm-5">
						<div className="inner-banner-img">
							<img src={aboutUs} />
						</div>
					</div>
    			</div>
    		</div>
    	</section> 
        </>      
    );
}