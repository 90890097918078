import React from 'react';
import demoScehdule from './images/demoScehdule.png'
import {captureGAEvent} from "../../../utils/common"
import { Link } from "react-router-dom";

export default function HomeSubscribe(){
    return (			
    <section className="lorem-sec">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-sm-7">
                    <div className="heading heading-desc">
                        <h2>Why wait?</h2>
                        <p>Ready to change lives? Schedule a demo with one of our team to see what TeachersDais can do for your business.</p>
                        <Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Content Box - Why wait", document.location)} className="for-btn teacher"><b>Schedule A FREE Demo</b></Link>                    
                    </div>
                </div>
                <div className="col-lg-6 col-sm-5">
                    <div className="lorem-img">
                        <img src={demoScehdule} />
                    </div>
                </div>
            </div>
        </div>
    </section>        
    );
}