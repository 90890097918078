import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { countryCodeList } from "../../../utils/utility"
import {captureGAEvent} from "../../../utils/common"
import("../../../assets/css/loader.css")

export default function ContactUsEnquiries(props){

    const { register, formState: { errors }, handleSubmit } = useForm();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('none');
    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('text-success');
    var phoneCodes = [];
    const tempCodes = countryCodeList.sort((a, b) => a.dial_code.localeCompare(b.dial_code));
    phoneCodes.push(<option key="000" value="">Country Code</option>);
    tempCodes.forEach((item, i)=>{
        phoneCodes.push(
            <option key={i} value={item.dial_code}>{item.dial_code}&nbsp;{item.code}</option>
        );
    })    

    useEffect(() => {
        if(props.contactUsSaveSuccess && props.contactUsSaveSuccess !== "") {
            setLoadingStatus ("none");
            setMessage("Thank you for getting in touch, we always try our best to respond as soon as possible, you can expect a reply in at most 48 hours.");
            setMessageClass('text-success')
        }

        if(props.contactUsSaveError && props.contactUsSaveError !== "") {  
            setButtonDisabled(false);
            setLoadingStatus ("none");          
            setMessage(props.contactUsSaveError);
            setMessageClass('text-danger')
        }
    },[props.contactUsSaveSuccess, props.contactUsSaveError]);

    const onSubmit = (data) => {  
        setLoadingStatus ("block"); 
        setButtonDisabled(true);
        const payload = {
            customername: data.name,
            customerphone: data.countryCode + data.phone,
            fromemail: data.email,
            message: data.comment,            
        }
        props.contactUsSave(payload);    
        document.getElementById("contact-us-form").reset(); 
        document.getElementById("comment").value = "";  
        var customerDetails =  "Phone: " + data.countryCode + " " + data.phone;
        captureGAEvent("Contact Us", data.name, customerDetails); 
    };

    return (			
        <div className="col-lg-6 col-sm-6 contact">            
            <div className="contact-form">
                <h5>Get in touch with us</h5>                
                <form onSubmit={handleSubmit(onSubmit)} method="POST" id="contact-us-form">
                    <div class="loader" style={{display:loadingStatus}}>
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="bar4"></div>
                        <div class="bar5"></div>
                        <div class="bar6"></div>
                    </div>
                    <p className={messageClass}>{message}</p>
                    <div className="form-group">
                        <input  name="name" {...register("name", { required: true })} className="form-control" placeholder="Name"/>
                        {errors.name && <span className="validation-error">Name is Required</span>}  
                    </div>  
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                            <select name="countryCode" className="form-control" {...register("countryCode", { required: true })}>
                                {phoneCodes}
                            </select>
                            {errors.countryCode && <span className="validation-error">Country code is Required</span>}
                        </div> 
                      </div>
                      <div className="col-md-6"> 
                        <div className="form-group">
                            <input type="tel" id="phone" className="form-control" name="phone" {...register("phone", {required: true, pattern:/^[0-9]+$/})} placeholder="Phone Number"/>
                            {errors.phone && errors.phone.type !== "pattern" && <span className="validation-error">Phone number is Required</span>}
                            {errors.phone && errors.phone.type === "pattern" && <span className="validation-error">Invalid phone number</span> } 
                        </div>
                      </div>  
                    </div> 
                    <div className="form-group">
                        <input type="email" id="email" className="form-control" name="email" placeholder="Email" {...register("email", {required: true, pattern:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/})}/>
                        {errors.email && errors.email.type !== "pattern" && <span className="validation-error">Email is Required</span>}
                        {errors.email && errors.email.type === "pattern" && <span className="validation-error">Invalid email</span> } 
                    </div>
                    <div className="form-group">
                        <textarea {...register("comment", { required: false })} rows="4" className="form-control" cols="50" id="comment" name="comment" form="usrform" placeholder="Type message"></textarea>
                    </div>
                    <button type="submit" className="btn sumbit-btn" disabled={buttonDisabled}>SUBMIT</button>
                    <p>By signing you are agreeing to our terms and acknowledge reading our privacy notice.</p>
                </form>
            </div>
        </div>        
    );
}