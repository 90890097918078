import React from 'react';
import { Link } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";

const Card = (props) => {
    const { cardKey, courseImg, coverImg, courseTitle, rating, description, currencyCode, bookFreeTrial, courseLink, grade } = props;


    const bookTrial = (bookURL) => {
        window.open(bookURL, '_blank')
    }

    return (
        <>
            <div key={cardKey} className="col-md-4 curriculum-custom-width pb-4"  >
                <div className="card-info">
                    <div className="card-bg">
                        <Link to="#" className="slider_info" onClick={() => bookTrial(courseLink)}>
                            <div style={{ height: "250px" }} >
                                {
                                    courseImg != null ?
                                        (<img className="img-fluid card-img-top rounded-bottom curriculum-image-homepage card_course_img" height='100px' src={courseImg} alt="star" />) :
                                        (<img className="img-fluid card-img-top rounded-bottom curriculum-image-homepage card_course_img" src={process.env.PUBLIC_URL + '/assets/img/curriculum_image_blank.jpg'} alt="" />)
                                }
                            </div>
                        </Link>
                        <div className="card-block pb-5 card-bg-min-height">
                            <div className="d-flex align-items-center">
                                <figure className="profile profile-inline card-image-flex">
                                    {
                                        coverImg != null ?
                                            (<img src={coverImg} className="profile-avatar" alt="" />) :
                                            (<img className="profile-avatar" src={process.env.PUBLIC_URL + '/assets/img/curriculum_icon_blank.png'} alt="" />)
                                    }
                                </figure>
                                <h4 className="card-title">{courseTitle}</h4>
                            </div>
                            {grade != "" ? (<p className="cardGrade">Grade : {grade}</p>) : (<p className="cardGrade">Grade : Not Specified</p>)}
                            <div className='course_rating'>
                                <p className="sub-title">Rating :</p>
                                <div className='rating_star'>
                                    <ReactStars
                                        count={5}
                                        value={rating}
                                        size={24}
                                        edit={false}
                                        activeColor="#ffd700"
                                    />
                                </div>
                            </div>
                            <div className="card-text cardDescriptio"><p dangerouslySetInnerHTML={{ __html: description && description.length > 150 ? `${description.substring(0, 150)}...` : description }}></p></div>
                            <hr />
                            {
                                (currencyCode !== null) ?
                                    (
                                        <>
                                            {/* <p className=" card-rate text-center"><small>Starting From</small> {new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(course.price)}</p> */}
                                            <Link className="btn-cart divcentr" to='#' onClick={() => bookTrial(bookFreeTrial)}><i className="fa fa-info-circle"></i>Book a Free Trial</Link>
                                        </>
                                    ) : (<Link className="btn-cart divcentr" to="#"><i className="fa fa-info-circle"></i>Coming Soon</Link>)
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Card