import React from 'react';
import global from './images/global.png'
import { Link } from "react-router-dom";
import {captureGAEvent} from "../../../utils/common"

export default function HomeContentTwo(){
  return (			
    <section className="global">
      <div className="container">
        <div className="teacher-title heading-desc">
          <h4 className="text-align-left">Why stay local when you can go global?</h4>
          <div className="row">
            <div className="col-lg-7" style={{textAlign:"left"}}>
                <p>If you’ve got knowledge to share with the world, Teachers’ Dais has the platform to help you do it. At Teachers’ Dais, we believe knowledge should not be constrained by geography. It’s our mission to help spread the knowledge of individual teachers, coaching centers and institutions across the world.</p>
                <p>Our unique and customizable platform will help you build your online presence as a teacher, plan and run your courses, manage your cohorts, and reach the students who need your expertise.</p>
                <p>You’ve got the knowledge, we’ve got the platform. Let us help you reach the world.</p>
                <Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Content Box - Why stay local", document.location)} className="for-btn teacher"><b>Schedule A FREE Demo</b></Link>
            </div>
            <div className="col-lg-5">
              <div className="botem-img">
                <img src={global}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>          
  );   
}