import React from 'react';
import SubBanner from './SubBanner';
import SubTier from './SubTier';
import SubContent from './SubContent';
import HomeContentTwo from '../Home/HomeContentTwo';

class Subscription extends React.Component {
    constructor(props) {
      super(props);
    }    
    render() {
      return (			
        <>
          <SubBanner />
          <SubTier />     
          <SubContent />     
          <HomeContentTwo />                           
        </>              
      );
    }
}

export default Subscription;