import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Layout from '../../components/Layout';

const mapStateToProps = createStructuredSelector({
  
});

export function mapDispatchToProps(dispatch) {
  return {    
    dispatch,
  };
}

const LayoutContainer = connect(mapStateToProps,mapDispatchToProps)(Layout);

export default LayoutContainer;