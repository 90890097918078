import React, { useState, useEffect } from 'react';
import {captureGAEvent} from "../../utils/common"
import marketData from './MarketplaceMOckData.json';
import Card from './Card';
import Group16 from './Image/Group16.png';

const CardBody = () => {
    const [courseName, setCourseName] = useState("");
    const [filteredCourseData, setfilteredCourseData] = useState([]);
    const [searchCourseName, setSearchCourseName] = useState("");
    const [quickSearch, setQuickSearch] = useState([]);

    const getKey = (index, ind) => {
        return (index + '.' + ind)
    }

    const NoCardData = ({ textColor }) => {
        return (
        <div className='noDataText m-auto'><h5 className='text-center ' style={{ color: textColor }}>
            We're Sorry!<br />
            We can't seem to find any products that match your search for "{courseName}"
        </h5></div>)
    }

    const sendCourseName = (e) => {
        const val = e.target.value;
        setCourseName(val)
        setSearchCourseName(val)
        window.scrollTo(0, 300);
    }

    const enterSubmit = (e) => {
       if(e.key === 'Enter'){
        setInputCourseName()
       }
    }

    const setInputCourseName = () => {
        captureGAEvent("Marketplace", "Search Keyword", `${searchCourseName}`)
        setCourseName(searchCourseName);
        window.scrollTo(0, 300);
        setSearchCourseName(courseName)
    }

    const filterArr = () => {
        const arrData = [];
        const quicksearchData = [];
        marketData.map((item, index) => (
            item.courses.map((course, ind) =>
                arrData.push(course)
            )))
        const filteredData = arrData.filter((course, index) =>
            (course.courseTitle.toUpperCase().includes(courseName.toUpperCase()))
        )
        if (filteredData && filteredData.length != 0) {
            setfilteredCourseData(filteredData)
        }
        if (filteredData.length == 0) {
            setfilteredCourseData([])
        }
        const quickData = filteredData.slice(0,4)
        setQuickSearch(quickData)
    }


    useEffect(() => {
        filterArr();
        setSearchCourseName("")
    }, [courseName])


    return (<>
        <div className='marketPlace_body'>
            <div className='card_banner'>
                <section className='card_left_div'>
                    <h2 className='card_bannerText banner_headText pb-2'>SMART</h2>
                    <h5 className='card_bannerText pb-2'>
                    <span className='bannerTxt'>S</span>tudent's <span className='bannerTxt'>M</span>arketplace for <span className='bannerTxt'>A</span>cademic <span className='bannerTxt'>R</span>esearch and <span className='bannerTxt'>T</span>utoring
                    </h5>
                    <p className='card_bannerText pb-2'>
                        A Unique Platform to Search for Tutors , Students and Course Details.
                    </p>
                    <div className="card_banner_searchBar">
                        <div className='card_SearchInputBtn pb-2'>
                            <div className="input-group input-group-lg card_searchInput">
                                <input type="text" className="form-control form-control-lg rounde bg-transparent text-white card_searchInputField" value={searchCourseName}  onChange={(e) => setSearchCourseName(e.target.value)} onKeyPress={(e) => enterSubmit(e)}
                                    placeholder="Search by speciality" aria-label="Type Keywords" aria-describedby="basic-addon2" />
                                <button type="submit" className=" btn card_bannerBtn" onClick={setInputCourseName}>Find a Teacher</button>
                            </div>
                        </div>
                        <div className='row searchBar_btn pl-3'>
                            {
                                quickSearch.map((course, ind) => (
                                    <div key={ind} className='pr-2 br-7' >
                                        <button type="button" className=" btn btn-rounded card_bannerText market_btn" onClick={sendCourseName} value={course.courseTitle} data-mdb-ripple-color="dark">{course.courseTitle}</button>
                                    </div>
                                ))
                            }


                        </div>
                    </div>
                </section>
                <section className='card_right_div'>
                    <div className='banner_img'>
                        <img className="img-fluid card-img-top rounded-bottom curriculum-image-homepage card_banner_img" src={Group16} alt="star" />
                    </div>
                </section>
            </div>
            <div className='main container-fluid'>
                <div className="row">
                    {(filteredCourseData && filteredCourseData.length != 0) ?
                        filteredCourseData.map((course, index) => (
                            <Card
                                cardKey={index}
                                courseImg={course.CourseImg}
                                coverImg = {course.CoverImg}
                                courseTitle={course.courseTitle}
                                rating={parseInt(course.Rating)}
                                description={course.Description}
                                currencyCode={course.currencycode}
                                bookFreeTrial={course.BookFreeTrial}
                                courseLink={course.CourseLink}
                                grade = {course.Grade} 
                            />
                        )) : (<NoCardData textColor='black' />)
                    }

                </div>
            </div>
        </div>
    </>
    )
}

export default CardBody




