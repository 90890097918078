import ReactGA from "react-ga4";

export const captureGA = (pageName) => {        
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: pageName });          
}

export const captureGAEvent = (category, action, label) => {        
    ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
        //value: 99, // optional, must be a number
        //nonInteraction: true, // optional, true/false
        //transport: "xhr", // optional, beacon/xhr/image
    });         
}