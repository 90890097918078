import React from 'react';
import vision from '../../../assets/images/vision.png'

export default function VisionBanner(){
    return (	
        <section className="about-baner top-baner">
    		<div className="container">
				<div className="row">    				
					<div className="col-lg-6 col-sm-7 align-self-center">
						<div className="heading heading-desc">
							<h2>Teach from anywhere</h2>
							<p>You’ve got the knowledge. We’ve got the platform. Together, we can teach the world.</p>
						</div>
					</div>
					<div className="col-lg-6 col-sm-5">
						<div className="inner-banner-img">
							<img src={vision} />
						</div>
					</div>
    			</div>    			
    		</div>
    	</section>
    );
}