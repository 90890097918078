import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Subscription from '../Subscription';
import ContactUs from '../../containers/ContactUs';
import Header from '../Header';
import Footer from '../Footer';
import Vision from '../Vision';
import About from '../About';
import Home from '../Home';
import Faq from '../Faq';
import Marketplace from '../Marketplace';

class Layout extends React.Component {
    constructor(props) {
      super(props);
    }    
    render(props) {
		return (
      <>
        <div>
          <Header {...this.props}/>
          <Routes>
              <Route exact path="/" element={<Home {...props}/>}></Route>  
              <Route exact path="/aboutus" element={<About {...props}/>}></Route>  
              <Route exact path="/faq" element={<Faq {...props}/>}></Route>   
              <Route exact path="/subscription" element={<Subscription {...props}/>}></Route>       
              <Route exact path="/vision" element={<Vision {...props}/>}></Route> 
              <Route exact path="/contactus" element={<ContactUs {...this.props}/>}></Route>  
              <Route exact path="/marketplace" element={<Marketplace {...props}/>}></Route>      
          </Routes>
          <Footer {...this.props}/>
        </div>			
      </>
		);
    }
}

export default Layout;