const initialState = {
    contactUsSave: '',
    contactUsSaveSuccess: '',
    contactUsSaveError: '',
};
  
export function contactUsReducer(state = initialState, action) {
    switch (action.type) {
        case  'CONTACT_US_SUBMIT_DATA':
            return {
                ...state,
            };    
        case  'CONTACT_US_SUBMIT_DATA_SUCCESS':            
            return {
                ...state,
                contactUsSaveSuccess: action.data
            };
        case  'CONTACT_US_SUBMIT_DATA_ERROR':
            return {
                ...state,
                contactUsSaveError: 'Some error occured, please contact our customer support'
            }; 
        case  'CONTACT_US_CLEAR_DATA':
            return {
                ...state,
                contactUsSaveSuccess: '',
                contactUsSaveError: '',
            };    
        default:
            return state;
    }
}