
import request, { headers } from "../../utils/request";
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { contactUsSaveSuccess , contactUsSaveError } from './actions';

export function* saveContactUsData(payload) {
    const data = payload.data || '';
    const DEFAULT_TIMEOUT = 20000;
    const requestURL = `${process.env.REACT_APP_TD_URL}/contactus`;
    try{
        const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
        yield put(contactUsSaveSuccess(response));
    } catch (error) {
      yield put(contactUsSaveError(error));
    }    
  }

function* actionWatcher() {
    yield takeLatest('CONTACT_US_SUBMIT_DATA', saveContactUsData)
}

export default function* rootSaga() {
  yield all([
    actionWatcher(),
  ]);
}