import React from 'react';
import {captureGAEvent} from "../../../utils/common"

export default function ContactUsLocations(){
    return (			
        <div className="col-lg-6 col-sm-6">
            <div className="left-content">                
                <div className="contact-content whatsapp-desktop">
                    <div className="icon-content">
                        <span className="contact-icons"><i className="fa fa-whatsapp whatsapp-icon-color" aria-hidden="true"></i></span>
                        <span className="contact-title"><a href="https://web.whatsapp.com/send?phone=12243028188&amp;text=I+want+to+digitise+my+institution+with+TeachersDais.+Please+assist+me+further%3A&amp;app_absent=0" className="whatsapp-icon-text" onClick={() => captureGAEvent("Social Media", "WhatsApp", "Contact Us Desktop")}>WhatsApp</a></span>
                    </div>                    
                </div>
                <div className="contact-content whatsapp-mobile">
                    <div className="icon-content">
                        <span className="contact-icons"><i className="fa fa-whatsapp whatsapp-icon-color" aria-hidden="true"></i></span>
                        <span className="contact-title"><a href="https://api.whatsapp.com/send/?phone=12243028188&amp;text=I+want+to+digitise+my+institution+with+TeachersDais.+Please+assist+me+further%3A&amp;app_absent=0" className="whatsapp-icon-text" onClick={() => captureGAEvent("Social Media", "WhatsApp", "Contact Us Mobile")}>WhatsApp</a></span>
                    </div>                    
                </div>
                <div className="contact-content">
                    <div className="icon-content">
                        <span className="contact-icons"><i className="fa fa-whatsapp" aria-hidden="true"></i></span>
                        <span className="contact-title">Enquiries</span>
                    </div>
                    <div className="country-content">
                        <h4>USA: +1 224 399 7101</h4>
                        <h4>IND: +91 85477 25520</h4>
                    </div>
                </div>
                <div className="contact-content">
                    <div className="icon-content">
                        <span className="contact-icons"><i className="fa fa-home" aria-hidden="true"></i></span>
                        <span className="contact-title">Office Locations</span>
                    </div>
                    <div className="country-content">
                        <h4>USA</h4>
                        <p>CodeBees LLC <br/>384 Chicory Ln, <br/>Buffalo Grove, IL, 60089</p>
                    </div>
                    <div className="country-content">
                        <h4>INDIA</h4>
                        <p>CodeBees Software Solutions Private Limited <br/> TC 40776, Alapuram Lane, <br/>Thirumala PO, <br/>Thiruvananthapuram 695006</p>
                    </div>
                    <div className="country-content">
                        <h4>CANADA</h4>
                        <p>CodeBees LLC <br/> 5871 Victoria Ave, Suite# 101<br/>Montreal, Quebec</p>
                    </div>
                </div>  
            </div>
        </div>        
    );
}