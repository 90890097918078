import React from 'react';

export default function FaqSection(){
    return (	
        <section className="faq-sec">
    		<div className="container">
    			<div className="heading-wrapper heading-desc">
    				<h2>Frequently Asked Questions</h2>
    			</div>

    			<div id="accordion" className="accordion">
			        <div className="card mb-0">
			            <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne">
			                <a className="card-title">
								How do I log in to the Admin Portal?
			                </a>
			            </div>
			            <div id="collapseOne" className="card-body collapse" data-parent="#accordion" >
			                <p>You can log in to the Admin Portal through your public website. After you’ve logged in with your username and password, the Dashboard link will be enabled for you.</p>
			            </div>

			            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
			                <a className="card-title">
								How do I log in to the Student Portal?
			                </a>
			            </div>
			            <div id="collapseTwo" className="card-body collapse" data-parent="#accordion" >
			                <p>You can log in to the Student Portal through your teacher’s website. After you’ve logged in with your username and password, the Dashboard link will be enabled for you.</p>
			            </div>

			            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
			                <a className="card-title">
								How do I log in to the Teacher Portal?
			                </a>
			            </div>
			            <div id="collapseThree" className="card-body collapse" data-parent="#accordion" >
			                <p>You can log in to the Teacher Portal through your public website. After you’ve logged in with your username and password, the Dashboard link will be enabled for you.</p>
			            </div>

			            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
			                <a className="card-title">
								How do I customize my public website?
			                </a>
			            </div>
			            <div id="collapseFour" className="card-body collapse" data-parent="#accordion" >
			                <p>You can customize your website through the Admin Portal. You can edit your content, change your domain name, upload your logo and add images, choose your site’s icon and theme, and more to build your own unique business website.</p>
			            </div>

			            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive">
			                <a className="card-title">
								Does the TeachersDais team monitor content on the platform?
			                </a>
			            </div>
			            <div id="collapseFive" className="card-body collapse" data-parent="#accordion" >
			                <p>TeachersDais does not monitor the content of the courses available on our platform. Our customers are fully responsible for ensuring that the content they make available is of the highest standard.</p>
			            </div>

						<div className="heading-desc">
							<h4><br/>Classes and coursework<br/></h4>
						</div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix">
			                <a className="card-title">
								How many classes are included in a free trial?
			                </a>
			            </div>
			            <div id="collapseSix" className="card-body collapse" data-parent="#accordion" >
			                <p>Students can access a single class during their free trial.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven">
			                <a className="card-title">
								Can teachers assign homework and assignments through the platform?
			                </a>
			            </div>
			            <div id="collapseSeven" className="card-body collapse" data-parent="#accordion" >
			                <p>Yes!</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEight">
			                <a className="card-title">
								Which video conferencing platforms do you support?
			                </a>
			            </div>
			            <div id="collapseEight" className="card-body collapse" data-parent="#accordion" >
			                <p>As of now, we support Zoom and Google Meet. If you’ve got a license, we’ll get you integrated. If you don’t have a license, you can still integrate with TeachersDais, but your video conference time limits will depend on the platform.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseNine">
			                <a className="card-title">
								Can students schedule individual classes?
			                </a>
			            </div>
			            <div id="collapseNine" className="card-body collapse" data-parent="#accordion" >
			                <p>Yes, students can schedule one-on-one classes through their student dashboard, based on teacher availability.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse11">
			                <a className="card-title">
								Can I access video recordings of my classes?
			                </a>
			            </div>
			            <div id="collapse11" className="card-body collapse" data-parent="#accordion" >
			                <p>Class video recordings will be available as part of our Q1, 2022 feature release.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse12">
			                <a className="card-title">
								How can I talk to my teacher outside of class?
			                </a>
			            </div>
			            <div id="collapse12" className="card-body collapse" data-parent="#accordion" >
			                <p>You can chat with your teacher through the messaging feature in your student dashboard.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse13">
			                <a className="card-title">
								How can I offer coupons?
			                </a>
			            </div>
			            <div id="collapse13" className="card-body collapse" data-parent="#accordion" >
			                <p>Talk to your business provider about offering coupons to students.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse14">
			                <a className="card-title">
								Can a class be rescheduled?
			                </a>
			            </div>
			            <div id="collapse14" className="card-body collapse" data-parent="#accordion" >
			                <p>Students can cancel and re-book one-on-one classes based on teacher availability (visible through the platform).</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse15">
			                <a className="card-title">
								Can I schedule classes across multiple time zones?
			                </a>
			            </div>
			            <div id="collapse15" className="card-body collapse" data-parent="#accordion" >
			                <p>TeachersDais supports class scheduling in all time zones.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse16">
			                <a className="card-title">
								Can I enroll in more than one course at a time?
			                </a>
			            </div>
			            <div id="collapse16" className="card-body collapse" data-parent="#accordion" >
			                <p>Yes, you can enroll in as many courses as you like.</p>
			            </div>

						<div className="heading-desc">
							<h4><br/>Payments<br/></h4>
						</div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse17">
			                <a className="card-title">
								Which payment gateways do you integrate with?
			                </a>
			            </div>
			            <div id="collapse17" className="card-body collapse" data-parent="#accordion" >
			                <p>For one time (package) payments, we support Google Pay, Stripe, and PayPal. For monthly subscriptions, we support Stripe and PayPal.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse18">
			                <a className="card-title">
								Which currencies do you support?
			                </a>
			            </div>
			            <div id="collapse18" className="card-body collapse" data-parent="#accordion" >
			                <p>We support every currency around the world.</p>
			            </div>

						<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse19">
			                <a className="card-title">
								Is your platform secure?
			                </a>
			            </div>
			            <div id="collapse19" className="card-body collapse" data-parent="#accordion" >
			                <p>Yes. TeachersDais is a fully secured cloud-based platform. We follow international security policies and requirements.</p>
			            </div>


			        </div>
			    </div>
    		</div>
    	</section>     
    );
}