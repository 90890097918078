import React from 'react';
import Slider from "react-slick";
import teacherDashboard from './images/teachers.png'
import studentDashboard from './images/students.png'
import adminDashboard from './images/admin.png'
import peopleDashboard from './images/people.png'
import { Link } from "react-router-dom";
import {captureGAEvent} from "../../../utils/common"

export default function HomeDashboards(){

    const settings_home_dashboard = {
        dots: false,
		speed: 800,
		autoplay: false,
		autoplaySpeed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					// dots: true
				}
			},
			{
			breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
			breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
			breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
					}
			}
		]
    };

    return (			
    <section className="sign-up">
    <div className="container">
        <div className="heading-wrapper sign-up-content heading-desc align-self-center">
            <h2>Go digital to go global</h2>
            <p className="font-bold-500">Take your courses online and reach an infinitely wider audience. With personalized dashboards for teachers, students, and administrators, as well as a customized website to market your courses, you can deliver a complete online educational experience to your students.</p>
        </div>
        <div>
        <Slider {...settings_home_dashboard} className="signup-slider">
            <div className="slick-slider-sign">
                <div className="top-slider-content">                    
                    <div className="text-title d-inline">Brand Website</div>                    
                </div>
                <div className="top-img">
                    <img src={peopleDashboard}/>
                </div>
                <div className="content-title heading-desc">	
                    <p>Manage your brand from one place</p>			    		
                    <p>Get a fully customizable, personal website for your business. Passionate individuals and thriving small business need a place to share the products and services with the world. We at TeachersDais make it happen.</p>
                    <Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Brand Website", "Home Dashboard Section")} className="for-btn teacher"><b>Schedule A FREE Demo</b></Link>
                </div>
            </div>	
            <div className="slick-slider-sign">
                <div className="top-slider-content">                   
                    <div className="text-title d-inline left-space">Admin Dashboard</div>                    
                </div>
                <div className="top-img">
                    <img src={adminDashboard}/>
                </div>
                <div className="content-title heading-desc">	
                    <p>Take your institution online</p>			    		
                    <p>Manage teachers, students and syllabi all from a single dashboard. Manage student and teacher profiles, payments, tasks, and duties, and track progress with infographic data.</p>
                    <Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Admin Website", "Home Dashboard Section")} className="for-btn teacher"><b>Schedule A FREE Demo</b></Link>
                </div>
            </div>
            <div className="slick-slider-sign">
                <div className="top-slider-content">                    
                    <div className="text-title d-inline">Teacher Dashboard</div>                   
                </div>
                <div className="top-img">
                    <img src={teacherDashboard}/>
                </div>
                <div className="content-title heading-desc">
                    <p>Teach from a single platform</p>				    		
                    <p>Get a holistic view of your courses, your students, and their performance. Schedule courses, set up online assessments, manage student tasks, mark attendance and update your availability.</p>
                    <Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Teacher Website", "Home Dashboard Section")} className="for-btn teacher"><b>Schedule A FREE Demo</b></Link>
                </div>
            </div>
            <div className="slick-slider-sign">
                <div className="top-slider-content">                    
                    <div className="text-title d-inline">Student Dashboard</div>                   
                </div>
                <div className="top-img">
                    <img src={studentDashboard}/>
                </div>
                <div className="content-title heading-desc">	
                    <p>Stay connected while learning</p>			    		
                    <p>Unlock the power of online learning with your very own dashboard. Stay organized with scheduling and assignment management, and chat to your teachers in real time when you need help.</p>
                    <Link to="/contactus" onClick={() => captureGAEvent("Schedule a demo", "Student Website", "Home Dashboard Section")} className="for-btn teacher"><b>Schedule A FREE Demo</b></Link>
                </div>
            </div>            			   				
        </Slider>
        </div>            
    </div>
    </section>          
    );
}