
import './index.css';
import App from './App';

import React from 'react';
import createSagaMiddleware from 'redux-saga';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom'; 
import { Provider } from 'react-redux';
import { logger, createLogger } from 'redux-logger';
import createRootReducer from './reducers'
import { saveState } from './PresistedStore';
import rootSaga from './sagas';
import Layout from './containers/Layout';
//import reducer from './reducers';
//import App from './components/App';
//import rootSaga from './sagas';

// Google Analytics code
import ReactGA from "react-ga4";
if(process.env.REACT_APP_GA_CODE && process.env.REACT_APP_GA_CODE !== "") {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE);
  ReactGA.send("pageview");
}

const sagaMiddleware = createSagaMiddleware();

// Need to be modified in production -  VERY IMPORTANT
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory({basename:process.env.PUBLIC_URL});

const configureStore=(preloadedState)=>{
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware, logger
        // ... other middlewares ...
      ),
    ),
  )

  return store
}

const store = configureStore();
sagaMiddleware.run(rootSaga);

store.subscribe(()=>{
  saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <div>
        <Layout history={history}/>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);