import React from 'react';
import Slider from "react-slick";

export default function HomeWhyTD(){

    const settings_home_why_td = {
        dots: true,
		infinite: true,
		lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1
    };

    return (		
        <>	
        <section className="why-teacher desktop">
            <div className="container">
                <div className="teacher-title heading-desc">
                    <h4>Why TeachersDais?</h4>
                    <p>TeachersDais is the ultimate solution for teachers, academies and institutions looking for a single hub that allows them to take their businesses online and reach students the world over.</p>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-desktop web icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height">
                                <h4>Get your own website</h4>
                                <p>Customize and brand your teaching site to advertise your courses, and even bring your own domain.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-dollar online icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height1">
                                <h4>Get paid online</h4>
                                <p>Give your students the flexibility of paying in any currency with Stripe, PayPal and Google Pay.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-play live-video icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height2">
                                <h4>Conduct live video classes</h4>
                                <p>Integrate with Zoom and Google Meet to recreate the classroom online.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-users schudle icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height3">
                                <h4>Schedule individual and group classes</h4>
                                <p>Structure syllabus timetables and schedule individual and group classes in a flexible way that works for everyone.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-comments chat icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height4">
                                <h4>Chat with students</h4>
                                <p>Engage with your students outside of class time with convenient inbuilt instant messaging.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-tasks manzment icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height5">
                                <h4>Assign and manage projects</h4>
                                <p>Assign tasks, manage your lectures and organize student activities all from a central dashboard.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-copy manzment icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height5">
                                <h4>Online assessments</h4>
                                <p>Assign online assessments for each of your curriculums from your dashboard.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="why-teacher mobile">
            <div className="container">
                <div className="teacher-title heading-desc">
                    <h4>Why TeachersDais?</h4>
                    <p>TeachersDais is the ultimate solution for teachers, academies and institutions looking for a single hub that allows them to take their businesses online and reach students the world over.</p>
                </div>
                <div id="why-teacher">
                <Slider {...settings_home_why_td} className="testmonial-slider">
                    <div className="testmonial-box">
                            <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-desktop web icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height">
                                <h4>Get your own website</h4>
                                <p>Customize and brand your teaching site to advertise your courses, and even bring your own domain.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="testmonial-box">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-dollar online icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height1">
                                <h4>Get paid online</h4>
                                <p>Give your students the flexibility of paying in any currency with Stripe, PayPal and Google Pay.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="testmonial-box">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-play live-video icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height2">
                                <h4>Conduct live video classes</h4>
                                <p>Integrate with Zoom and Google Meet to recreate the classroom online.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="testmonial-box">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-users schudle icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height3">
                                <h4>Schedule individual and group classes</h4>
                                <p>Structure syllabus timetables and schedule individual and group classes in a flexible way that works for everyone.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="testmonial-box">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-comments chat icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height4">
                                <h4>Chat with students</h4>
                                <p>Engage with your students outside of class time with convenient inbuilt instant messaging.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="testmonial-box">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-tasks manzment icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height5">
                                <h4>Assign and manage projects</h4>
                                <p>Assign tasks, manage your lectures and organize student activities all from a central dashboard.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                    <div className="testmonial-box">
                        <div className="teacher-icon-box">
                            <div className="top-icon">
                                <i className="fa fa-copy manzment icon-size" aria-hidden="true"></i>
                            </div>
                            <div className="icon-box-title height5">
                                <h4>Online assessments</h4>
                                <p>Assign online assessments for each of your curriculums from your dashboard.</p>
                            </div>
                            <a href="#" className="read-more">Read more</a>
                        </div>
                    </div>
                </Slider>
                </div>
            </div>
        </section>
        </>         
    );
}