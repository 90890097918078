import React from 'react'
import './Marketplace.css'
import CardBody from './CardBody';

const Marketplace = () => { 
    return (
        <div>
            <CardBody/>
        </div>
    )
}

export default Marketplace;